import React from 'react';
import css from './Header.module.scss';
import logo from '../../logo512.png';
import { URL } from "../../helpers/config";
import { gtmAttr, CATEGORY, ACTION, LABEL } from "../../helpers/gtm";
import { isLocalOrBeta } from "../../helpers/app";

interface Props {
  text: string;
  appVersion: string;
}

function Header(props: Props) {

  const betaString = isLocalOrBeta() ? "Beta" : "";

  return (
    <div className={`${css.container} expand`} data-tour="header">
      <div className={`${css["item-1"]}`} data-tour="logo">
        <a href={URL.BLS_WEBSITE} target="_blank" rel="noopener noreferrer" {...gtmAttr(CATEGORY.OUT_BOUND_CLICK, ACTION.BLS_WEBSITE, LABEL.HEADER)}>
          <img src={logo} alt="logo" className={`${css.logo}`} />
        </a>
      </div>
      <div className={`${css["item-2"]}`}>
        <div className={`${css.text}`}>
          <h2>
            <span>{props.text}</span>
            <span className={`${css.version}`} data-tour="version" >v{props.appVersion} {betaString}</span>
          </h2>
        </div>
      </div>
    </div>
  )
}

export default Header;
