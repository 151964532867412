import React from 'react';
import css from './Lap.module.scss'

interface Item {
  split: string;
  time: string;
  speed: string;
  rpm: number | string;
  number: number | string;
  lapDifference?: number | null;
  totalDifference?: number | null;
}

interface Props {
  item: Item;
  isHeader?: boolean;
}

const Lap = ({ isHeader, item: { number, split, time, rpm, speed, lapDifference, totalDifference } }: Props) => {

  const lapDifferenceCss = lapDifference && lapDifference >= 0 ? css.positive : css.negative;
  const totalDifferenceCss = totalDifference && totalDifference >= 0 ? css.positive : css.negative;

  return (
    <div className={`${css.container} ${isHeader ? css.header : ''}`}>
      <div className={`${css.item} ${css["item-1"]}`}>
        {number}.
      </div>
      <div className={`${css.item} ${css["item-split-time"]}`}>
        <span>{split}</span>
        {lapDifference !== null && <span className={`${css.diff} ${lapDifferenceCss}`}>{lapDifference}</span>}
      </div>
      <div className={`${css.item} ${css["item-3"]}`}>
        <span>{time}</span>
        {totalDifference !== null && <span className={`${css.diff} ${totalDifferenceCss}`}>{totalDifference}</span>}
      </div>
      <div className={`${css.item} ${css["item-4"]}`}>
        <span>{rpm}</span>
        <span className={`${css.rpm}`}></span>
      </div>
      <div className={`${css.item} ${css["item-5"]}`}>
        <span>{speed}</span>
        <span className={`${css.km}`}></span>
      </div>
    </div>
  )
}

export default Lap;