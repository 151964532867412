import React, { useState } from 'react';
import css from './NewVersionAvailable.module.scss';
import { Props } from './Props';
import { unregister } from '../../serviceWorker';


const NewVersionAvailable = (props: Props) => {

  const [isLoading, setLoading] = useState(false);

  const redirect = () => {
    unregister();
    window.location.reload(true);
  }

  const reloadPage = (event: React.MouseEvent) => {
    event.preventDefault();
    setLoading(true);

    if (window.caches && Array.isArray(window.caches) && window.caches.length > 0) {
      caches.keys().then(files => Promise.all(files.map(file => caches.delete(file))).then(() => redirect()));
    } else {
      redirect();
    }
  }

  return (
    <div className={`${css.container}`}>
      {isLoading ? "Updating..." :
        <div>New version available, <a onClick={reloadPage} className={`${css.link}`} href="/">reload page</a></div>
      }
    </div>
  )
}

export default NewVersionAvailable;