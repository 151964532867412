import React from 'react';
import css from './CountDown.module.scss'

interface Item {
  split: string;
  time: string;
  speed: string;
}

interface Props {
  number: number;
}

const CountDown = (props: Props) => {
  return (
    <div className={`${css.container}`}>
      <div className={`${css.table}`}>
        <div className={`${css.row}`}>
          <div className={`${css.cell}`}>
            <h1>{props.number}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CountDown;