import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Home from './pages/Home/Home';
import Help from './pages/Help/Help';
import { EVENT, getAppMode, getIsIos } from './helpers/pwa';
import { gtmEvent, GTM_EVENT } from './helpers/gtm';
import meta from './meta.json';

import './App.scss';

//https://stackoverflow.com/questions/55565444/how-to-register-event-with-useeffect-hooks

const App = () => {
  let downloadPrompt: any = useRef(null);

  const appVersion = `0.3.${meta.minorVersion}`
  const environment = "prod";

  // https://developers.google.com/web/fundamentals/app-install-banners#safari
  const [isReadyToDownload, setReadyToDownload] = useState(false);
  const [isAppMode, setIsAppMode] = useState(getAppMode());
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);
  const [isIos] = useState(getIsIos());
  const [isInstalling, setIsInstalling] = useState(false);

  const beforeInstallPrompt = useCallback(event => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    event.preventDefault();
    setIsAppMode(getAppMode());
    // Show the download button
    setReadyToDownload(!isAppMode);
    // Stash the event so it can be triggered later.
    downloadPrompt.current = event;
    // Submit event to google tag manager
    gtmEvent(GTM_EVENT.APP_DOWNLOAD_READY);
  }, [isAppMode]);

  const appDownloaded = useCallback(event => {
    setTimeout(() => {
      // NOTE Maybe reload the page here
      setIsAppMode(getAppMode());
      setReadyToDownload(false);
      setIsInstalling(false);
      // Submit event to google tag manager
      gtmEvent(GTM_EVENT.APP_DOWNLOADED);
    }, 5000);
  }, []);

  const onDownload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.preventDefault();
    // Show the popup menu so that a user can install the app
    downloadPrompt.current.prompt();
    // Wait for the user to respond to the prompt
    downloadPrompt.current.userChoice.then((choiceResult: any) => {
      // Submit event to google tag manager
      gtmEvent(GTM_EVENT.APP_DOWNLOAD_CLICK);
      downloadPrompt.current = null;
      if (choiceResult.outcome === 'accepted') {
        console.debug('User accepted the A2HS prompt');
        setIsInstalling(true);
        gtmEvent(GTM_EVENT.APP_DOWNLOAD_ACCEPTED);
      } else {
        console.debug('User dismissed the A2HS prompt');
        // Submit event to google tag manager
        gtmEvent(GTM_EVENT.APP_DOWNLOAD_DISMISSED);
      };
    });
  };

  const newVersionAvailable = useCallback(() => {
    setIsNewVersionAvailable(true);
  }, [])

  useEffect(() => {
    window.addEventListener(EVENT.BEFORE_INSTALL_PROMPT, beforeInstallPrompt);
    window.addEventListener(EVENT.APP_INSTALLED, appDownloaded);
    window.addEventListener(EVENT.NEW_VERSION_AVAILABLE, newVersionAvailable);

    return () => {
      window.removeEventListener(EVENT.BEFORE_INSTALL_PROMPT, beforeInstallPrompt);
      window.removeEventListener(EVENT.APP_INSTALLED, appDownloaded);
      window.removeEventListener(EVENT.NEW_VERSION_AVAILABLE, newVersionAvailable);
    };
  }, [beforeInstallPrompt, appDownloaded, newVersionAvailable])

  return (
    <Router>
      <Switch>
        <Route path="/help">
          <Help />
        </Route>
        <Route path="/">
          <Home app={{
            isAppMode,
            isReadyToDownload,
            isInstalling,
            appVersion,
            environment,
            onDownload,
            isIos,
            isNewVersionAvailable
          }} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
