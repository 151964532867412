export interface Step {
    selector: string;
    content: string;
}

export const steps: Step[] = [
    {
        selector: '[data-tour="header"]',
        content: `BLS developers designed a start gate stopwatch as a training tool to help with every day training.
      We understand training alone or in small groups are difficult and hope that this will help, please give us your feedback on our social media pages.`,
    },
    {
        selector: '[data-tour="logo"]',
        content: 'Clicking on the BLS logo will take you to the Ecommerce website.',
    },
    {
        selector: '[data-tour="version"]',
        content: 'The current version of the app. We are still developing actively on the app. Share your input and ideas on the BLS social media pages.',
    },
    {
        selector: '[data-tour="help"]',
        content: 'Tapping the Question symbol will give you a tour of the app.',
    },
    {
        selector: '[data-tour="facebook"]',
        content: 'Tapping the Facebook icon will take you to the BLS Facebook page. Give us a like and share.',
    },
    {
        selector: '[data-tour="instagram"]',
        content: 'Tapping the Instagram icon will take you to the BLS Instagram page. Follow us here.',
    },
    {
        selector: '[data-tour="settings"]',
        content: 'Tapping the settings icon will allow you to set give you settings to configure.',
    },
    {
        selector: '[data-tour="startCounterAt"]',
        content: 'Here you can change the start number for the stopwatch.',
    },
    {
        selector: '[data-tour="gear"]',
        content: 'Here you can set the gear in inches to calculate the cadence.',
    },
    {
        selector: '[data-tour="distance"]',
        content: 'Here you can set the lap distance to calculate the speed in Km/h.',
    },
    {
        selector: '[data-tour="closeMenu"]',
        content: 'Here you can close the menu.',
    }
];

export const downloadAppStep: Step = {
    selector: '[data-tour="download-app"]',
    content: 'Click here to download the app to your phone',
}