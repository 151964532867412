

export enum CATEGORY {
    APP_DOWNLOAD = "App Download",
    STOPWATCH = "Stopwatch",
    OUT_BOUND_CLICK = "Out Bound Clicks",
    TOUR = "Tour"
}

export enum ACTION {
    // STOPWATCH
    STOPWATCH_START = "start",
    STOPWATCH_RESET = "reset",
    STOPWATCH_SAVE = "save",
    STOPWATCH_STOP = "stop",
    STOPWATCH_CONTINUE = "continue",
    STOPWATCH_LAP = "lap",
    STOPWATCH_DISTANCE = 'distance',

    // LINK_OUT
    FACEBOOK = 'facebook',
    INSTAGRAM = 'instagram',
    BLS_WEBSITE = 'bls-website',

    // TOUR
    TOUR_START = 'tour-start'
}

export enum LABEL {
    SLIDER = 'slider',
    INPUT = 'input',

    // OUT_BOUND_CLICK
    FOOTER = 'footer',
    HEADER = 'header'
}

export enum GTM_EVENT {
    APP_DOWNLOAD_CLICK = 'appDownloadClick',
    APP_DOWNLOAD_ACCEPTED = 'appDownloadAccepted',
    APP_DOWNLOAD_DISMISSED = 'appDownloadDismissed',
    APP_DOWNLOAD_READY = 'appDownloadReady',
    APP_DOWNLOADED = 'appDownloaded',

    DISTANCE_INPUT = 'distance-input',
    DISTANCE_SLIDER = 'distance-slider',
}

interface GTMAttr {
    "gtm-category": CATEGORY;
    "gtm-action": ACTION;
    "gtm-label": LABEL | string;
}

export const gtmAttr = (category: CATEGORY, action: ACTION, label?: LABEL): GTMAttr => {
    return {
        "gtm-category": category,
        "gtm-action": action,
        "gtm-label": label || ""
    }
}

//https://support.google.com/tagmanager/answer/7679219?hl=en&ref_topic=7679108
interface TrackCustomEventProps {
    eventAction?: string;
    eventLabel?: string;
}

export const gtmEvent = (event: GTM_EVENT, data: TrackCustomEventProps = {}): void => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event,
        ...data
    });
}