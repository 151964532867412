import { STOPWATCH } from './constants';

export const getCookie = (cname: COOKIE | string, defaultValue: string = ''): string => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return defaultValue;
}

export const setCookie = (name: COOKIE | string, value: string, expires: string = ''): void => {
    if (!expires) {
        const d = new Date();
        d.setTime(d.getTime() + (300 * 24 * 60 * 60 * 1000));
        expires = "expires=" + d.toUTCString();
    }

    document.cookie = `${name}=${value};${expires};path=/`;
}

export enum COOKIE {
    SETTINGS = 'settings'
}

export const getSettingsCookie = (): SettingsParams => {
    return Object.assign({}, {
        stopwatch: STOPWATCH.STANDING,
        distance: 250,
        gear: 12,
        startCounterAt: 12,
        lastStartCounterAt: 12,
        firstLapTime: 21,
        lapTime: 18,
    }, JSON.parse(getCookie(COOKIE.SETTINGS) || '{}'))
}

export const setSettingsCookie = (settings: SettingsParams): void => {
    setCookie(COOKIE.SETTINGS, JSON.stringify(Object.assign({}, getSettingsCookie, settings)));
}

export interface SettingsParams {
    stopwatch: STOPWATCH;
    distance: number;
    gear: number;
    startCounterAt: number;
    lastStartCounterAt: number;
    firstLapTime: number;
    lapTime: number;
}