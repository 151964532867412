import React from 'react';
import css from './Settings.module.scss';
import { Props } from './Settinings.Interface';
import { STOPWATCH } from '../../helpers/constants';
import { gtmEvent, ACTION, GTM_EVENT } from "../../helpers/gtm";
// import { isLocalOrBeta } from '../../helpers/app';

const Settings = ({ isOpen, settings, onChange }: Props) => {

  if (!isOpen) {
    return null;
  }

  // https://stackoverflow.com/questions/22199098/html5-is-there-an-onrelease-like-event-for-input-type-range
  const onDistanceRelease = (event: GTM_EVENT) => {
    gtmEvent(event, {
      eventAction: ACTION.STOPWATCH_DISTANCE,
      eventLabel: event === GTM_EVENT.DISTANCE_INPUT ? 'input' : 'slider'
    });
  }

  const onChangeStart = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const startCounterAt = parseInt(event.target.value.replace('.', '').replace(',', ''));
    const lastStartCounterAt = startCounterAt;
    onChange({ ...settings, startCounterAt, lastStartCounterAt });
  }

  const onGearChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const gear = parseFloat(event.target.value);
    onChange({ ...settings, gear })
  }

  const onDistanceChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const distance = parseFloat(event.target.value);
    onChange({ ...settings, distance })
  }

  const onChangeFirstLapTime = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const firstLapTime = parseFloat(event.target.value);
    onChange({ ...settings, firstLapTime });
  }

  const onChangeLapTime = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const lapTime = parseFloat(event.target.value);
    onChange({ ...settings, lapTime });
  }

  const onSelectStopwatch = (stopwatch: STOPWATCH) => {
    const startCounterAt = stopwatch === STOPWATCH.FLYING ? 0 : settings.lastStartCounterAt;
    onChange({ ...settings, stopwatch, startCounterAt });
  }

  const onFocus = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.target.focus();
    event.target.select();
  }

  return (
    <div className={`${css.container}`}>

      {/* <div className={`${css.field}`}>
          <div className={`${css.control} ${css.hasIconLeft}`}>
            <input className={`${css.input}`} value="test" />
            <span className={`${css.inputIcon}`}>
              icon
            </span>
          </div>
        </div> */}

      <div className={css.menuContainer}>
        <button className={STOPWATCH.FLYING === settings.stopwatch ? css.active : ''} onClick={() => onSelectStopwatch(STOPWATCH.FLYING)}>Flying</button>
        <button className={STOPWATCH.STANDING === settings.stopwatch ? css.active : ''} onClick={() => onSelectStopwatch(STOPWATCH.STANDING)}>Standing</button>
        <button className={STOPWATCH.PURSUIT === settings.stopwatch ? css.active : ''} onClick={() => onSelectStopwatch(STOPWATCH.PURSUIT)}>Pursuit</button>
      </div>

      {settings.stopwatch !== STOPWATCH.FLYING &&
        <div className={`${css['item-gear']}`} data-tour="startCounterAt">
          <input
            type="number"
            className={`${css.input}`}
            value={settings.startCounterAt}
            onChange={onChangeStart}
            onSelect={onFocus}
          />
          <span className={`${css['label']}`}>Start Counter At</span>
        </div>
      }

      <div className={`${css['item-gear']}`} data-tour="gear">
        <input
          type="number"
          className={`${css.input}`}
          value={settings.gear}
          onChange={onGearChange}
          onSelect={onFocus}
        />
        <span className={`${css['label']}`}>Gear in inches</span>
      </div>

      {settings.stopwatch === STOPWATCH.PURSUIT &&
        <div className={`${css['item-gear']}`} data-tour="gear">
          <input
            type="number"
            className={`${css.input}`}
            value={settings.firstLapTime}
            onChange={onChangeFirstLapTime}
            onSelect={onFocus}
          />
          <span className={`${css['label']}`}>First Lap Time</span>
        </div>
      }

      {settings.stopwatch === STOPWATCH.PURSUIT &&
        <div className={`${css['item-gear']}`} data-tour="gear">
          <input
            type="number"
            className={`${css.input}`}
            value={settings.lapTime}
            onChange={onChangeLapTime}
            onSelect={onFocus}
          />
          <span className={`${css['label']}`}>Lap Time</span>
        </div>
      }

      <div className={`${css['item-distance']}`} data-tour="distance">
        <div className={`${css['distance-container']}`}>
          <div className={`${css['distance-item-1']}`} data-tour="distance-input">
            <input
              type="number"
              className={`${css.input}`}
              value={settings.distance}
              onChange={onDistanceChange}
              onBlur={() => onDistanceRelease(GTM_EVENT.DISTANCE_INPUT)}
              onSelect={onFocus}
            />
            <span className={`${css['label']}`}>M</span>
          </div>
          <div className={`${css['distance-item-2']}`} data-tour="distance-slider">
            <input
              type="range"
              min="50"
              step="25"
              max="1000"
              className={`${css['slider']}`}
              value={settings.distance}
              onChange={onDistanceChange}
              onMouseUp={() => onDistanceRelease(GTM_EVENT.DISTANCE_SLIDER)}
              onTouchEnd={() => onDistanceRelease(GTM_EVENT.DISTANCE_SLIDER)}
            />
          </div>
        </div>
      </div>


    </div>
  )
}

export default Settings;