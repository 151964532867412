export const formatTime = (timestamp: number): string => {
    const date = new Date(timestamp);
    let time = [
        date.getMinutes().toString(),
        date.getSeconds().toString(),
        date.getMilliseconds().toString()
    ];

    // Milliseconds
    if (time[2].length === 1) {
        time[2] = "00" + time[2];
    } else if (time[2].length === 2) {
        time[2] = "0" + time[2];
    }

    // Seconds
    if (time[1].length > 2) {
        time[1] = "0" + time[0];
    }

    // Minutes
    if (time[0].length > 2) {
        time[0] = "0" + time[0];
    }

    return time.join(":");
}