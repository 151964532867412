import React from 'react';
// import { Link } from "react-router-dom";
import css from './Help.module.scss';

const Help = () => {

  
  const awe = ["sdf"];

  for (let i = 0; i < 100; i++) {
    awe.push(`${i}`)
  }

  return (
    <div className={`${css.container}`}>
      { 
        awe.map((item) =>  (
          <div className={`${css.item}`}>fdf</div>
        ))
      }
    </div>
  )
}

export default Help;