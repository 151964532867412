import React from 'react';

export interface Props extends React.Props<Props> {
  showAndroidButton: boolean;
  showIOSButton: boolean;
  selectedMenu: MENU | null;
  onMenuSelect(menu: MENU): void;
}

export enum MENU {
  DOWNLOAD_ANDROID = 'download_android',
  DOWNLOAD_IOS = 'download_ios',
  SETTINGS = 'settings',
  NONE = 'none',
  HELP = 'help',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}