import React, { useEffect, useState } from 'react';
import css from './Home.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { steps } from './tour-steps';
import Stopwatch from '../../components/Stopwatch/Stopwatch';
import Tour from 'reactour';
import { Props } from './interface';
import { getSettingsCookie, setSettingsCookie, SettingsParams } from '../../helpers/cookie';
import { MENU } from '../../components/Footer/Footer.Interface';
import Menu from '../../components/Menu/Menu';
import DownloadiOS from '../../components/DownloadiOS/DownloadiOS';
import NewVersionAvailable from '../../components/NewVersionAvailable/NewVersionAvailable';
import Settings from '../../components/Settings/Settings';
import DownloadAndroid from '../../components/DownloadAndroid/DownloadAndroid';
import Facebook from '../../components/Facebook/Facebook';
import Instagram from '../../components/Instagram/Instagram';
import Help from '../../components/Help/Help';

const Home = (props: Props) => {

  const text = "Start Gate Stopwatch";

  const {
    appVersion,
    isAppMode,
    environment,
    isReadyToDownload,
    isInstalling,
    onDownload,
    isNewVersionAvailable,
    isIos,
  } = props.app;

  const [isTourOpen, setIsTourOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(MENU.NONE);
  const [showFooter, setShowFooter] = useState(true);
  const [headerText, setHeaderText] = useState(text);

  const [settings, setSettings] = useState(getSettingsCookie());

  const onEvent = (event: string, distance: any) => {
    switch (event) {
      case "start":
        setShowFooter(false);
        break;
      default:
        setShowFooter(true);
        break
    }

    setHeaderText(distance || distance === 0 ? `Lap ${distance} Meter` : text);
  }

  const onMenuSelect = (menu: MENU): void => {
    if (menu === selectedMenu) {
      setSelectedMenu(MENU.NONE);
    } else {
      setSelectedMenu(menu);
    }
  }

  const updateSettings = (props: SettingsParams) => {
    setSettingsCookie(props);
    setSettings(getSettingsCookie());
  }

  const enjoy = {
    selector: '[data-tour="enjoy"]',
    content: 'Enjoy and feel free to give us feed back on the BLS social pages.',
  }

  if (!isAppMode && (isReadyToDownload || isIos)) {
    steps.push({
      selector: '[data-tour="download"]',
      content: 'Tap here to download the app to your device.',
    });
  }

  steps.push(enjoy)

  useEffect(() => {
    console.debug(`Home Page Rendered`);
    console.debug(`environment: ${environment}`);
    console.debug(`appVersion: ${props.app.appVersion}`);
    console.debug(`isAppMode: ${isAppMode}`);
    setSettings(getSettingsCookie())
  }, [setSettings, environment, isAppMode, props.app.appVersion])

  return (
    <div className={`${css.home} expand`}>
      <div className={`${css.row} ${css.header}`}>
        <Header
          text={headerText}
          appVersion={appVersion}
        />
      </div>
      {isNewVersionAvailable &&
        <div className={`${css.row} ${css.new_version}`}>
          <NewVersionAvailable />
        </div>
      }
      <div className={`${css.row} ${css.main}`}>
        <Stopwatch
          settings={settings}
          onEvent={onEvent}
        />
      </div>

      {showFooter &&
        <div className={`${css.row} ${css.footer}`}>

          <Menu isOpen={selectedMenu !== MENU.NONE} selectedMenu={selectedMenu} onClose={() => setSelectedMenu(MENU.NONE)}>
            <Settings
              isOpen={selectedMenu === MENU.SETTINGS}
              settings={settings}
              onChange={updateSettings}
            />

            <Facebook
              isOpen={selectedMenu === MENU.FACEBOOK}
            />

            <Instagram
              isOpen={selectedMenu === MENU.INSTAGRAM}
            />

            <DownloadAndroid
              isOpen={selectedMenu === MENU.DOWNLOAD_ANDROID}
              isInstalling={isInstalling}
              onDownload={onDownload}
            />

            <DownloadiOS
              isOpen={selectedMenu === MENU.DOWNLOAD_IOS}
            />

            <Help
              isOpen={selectedMenu === MENU.HELP}
              onClickTour={() => {
                setSelectedMenu(MENU.NONE);
                setIsTourOpen(true);
              }}
            />
          </Menu>

          <Footer
            showIOSButton={!isAppMode && !isReadyToDownload && isIos}
            showAndroidButton={!isAppMode && isReadyToDownload}
            selectedMenu={selectedMenu}
            onMenuSelect={onMenuSelect}
          />
        </div>
      }

      {isTourOpen &&
        <Tour
          isOpen={true}
          getCurrentStep={(step: number) => {
            console.log(step);
            if (step === 7 || step === 8 || step === 9 || step === 10) {
              if (selectedMenu !== MENU.SETTINGS) onMenuSelect(MENU.SETTINGS);
            } else {
              if (selectedMenu !== MENU.NONE) onMenuSelect(MENU.NONE);
            }
          }}
          steps={steps}
          onRequestClose={() => {
            setIsTourOpen(false);
            setSelectedMenu(MENU.NONE);
          }}
        />
      }
    </div>
  )
}

export default Home;
