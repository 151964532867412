import React from 'react';
import css from './Help.module.scss';
import { Props } from './Help.Interface';

const Help = ({ isOpen, onClickTour }: Props) => {

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`${css.container}`}>
      <p>Don't know how to use the stopwatch? Watch this video.</p>
      <iframe
        title="stopwatch"
        className={css.iframe}
        width="320"
        height="200"
        src="https://www.youtube.com/embed/AXw5IESP55A"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <p>OR click <a onClick={(e) => { e.preventDefault(); onClickTour() }} href="/tour" className={css.tourBtn}>here</a>  to take a tour of the app.</p>
    </div>
  )
}

export default Help;