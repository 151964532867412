import React from 'react';
import css from './Instagram.module.scss';
import { Props } from './Instagram.Interface';

const Instagram = ({ isOpen }: Props) => {

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`${css.container}`}>
      <h1>Follow Us On Instagram</h1>
      <a href="https://www.instagram.com/black_line_sprinting" target="_blank" rel="noopener noreferrer">www.instagram.com/black_line_sprinting</a>
    </div>
  )
}

export default Instagram;