import React from 'react';
import css from './DownloadiOS.module.scss';
import { Props } from './DownloadiOS.Interface';

const DownloadiOS = ({ isOpen }: Props) => {

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`${css.container}`}>
      <p>This is a Web App and can be used directly within your browser.</p>
      <p>You can also download it to your device by:</p>
      <ol className={`${css.ul}`}>
        <li>Tapping on the share <svg className={`${css.svg}`}><use href="#share-icon"></use></svg> button</li>
        <li>Scrolling down OR scroll to the right</li>
        <li>Select Add to homescreen</li>
      </ol>
      <svg className={`${css.svg_icon}`}>
        <defs>
          <symbol id="share-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><polyline points="16 6 12 2 8 6"></polyline><line x1="12" y1="2" x2="12" y2="15"></line></symbol>
        </defs>
      </svg>
    </div>
  )
}

export default DownloadiOS;


