import React from 'react';
import css from './Facebook.module.scss';
import { Props } from './Facebook.Interface';

const Facebook = ({ isOpen }: Props) => {

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`${css.container}`}>
      {/* <div
        className="fb-page"
        data-href="https://www.facebook.com/blacklinesprinting"
        data-tabs="timeline"
        data-width=""
        data-height=""
        data-small-header="true"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote cite="https://www.facebook.com/blacklinesprinting" className="fb-xfbml-parse-ignore">
          <a href="https://www.facebook.com/blacklinesprinting">Black Line Sprinting</a>
        </blockquote>
      </div> */}
      <h1>Follow Us On Facebook</h1>
      <a href="https://www.facebook.com/blacklinesprinting" target="_blank" rel="noopener noreferrer">www.facebook.com/blacklinesprinting</a>
    </div>
  )
}

export default Facebook;