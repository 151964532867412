import React from 'react';
import css from './DownloadAndroid.module.scss';
// import { gtmAttr, CATEGORY, ACTION, LABEL } from "../../helpers/gtm";
import { URL } from '../../helpers/config';
import { Props } from './DownloadAndroid.Interface';

const DownloadAndroid = ({ onDownload, isInstalling, isOpen }: Props) => {

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`${css.container}`}>
      <p>This is a Web App and can be used directly within your browser.</p>
      <p>You can also download the Web App to your device here.</p>
      <a onClick={onDownload} href={URL.BLS_WEBSITE} data-tour="download-app" target="_blank" rel="noopener noreferrer" title="Download" className={`${css["btn-download"]}`} id="downloadBtn">
        {isInstalling ? "Installing..." : "Download App"}
      </a>
    </div>
  )
}

export default DownloadAndroid;