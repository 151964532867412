export enum EVENT {
    BEFORE_INSTALL_PROMPT = "beforeinstallprompt",
    APP_INSTALLED = "appinstalled",
    NEW_VERSION_AVAILABLE = "newVersionAvailable",
}

export const getAppMode = (): boolean => window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true;

export const getIsIos = (): boolean => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
}