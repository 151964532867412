import React, { Component } from 'react';
import Lap from "../Lap";
import css from './Stopwatch.module.scss';
import { Howl } from 'howler';
import { gtmAttr, CATEGORY, ACTION } from "../../helpers/gtm";
import { formatTime } from '../../helpers/time';
import CountDown from './CountDown/CountDown';
import { State, Props } from './Stopwatch.Interfaces';
//@ts-ignore
import sound1 from '../../assets/sounds/beep1.mp3';
//@ts-ignore
import sound2 from '../../assets/sounds/beep2.mp3';
import { STOPWATCH } from '../../helpers/constants';

class Stopwatch extends Component<Props> {

  intervalTime: number = 10;
  time: number = 0;
  updateTimeStateEvery = 8;
  nth = 0;

  beep1 = new Howl({ src: [sound1] });
  beep2 = new Howl({ src: [sound2] });
  startTimestamp: any;
  stopTime: any;
  interval: any;
  soundIntervals: Record<number, boolean> = {};
  avgSpeed = 23;

  state: State = {
    isStopped: false,
    time: 0,
    lapDifference: 0,
    lapList: [],
    save: false,
  }

  updateState = (obj: any): void => {
    this.setState(Object.assign({}, this.state, obj));
  }

  count = (): void => {
    if (!this.state.isStopped) {
      const time: number = new Date().getTime() - this.startTimestamp;
      const interval: number = -Math.round(time / 1000);
      const sound = interval <= 0 ? this.beep2 : this.beep1;

      if (this.soundIntervals[interval] === false) {
        if (interval === 0) {
          sound.play(); // long beep
        } else {
          sound.play();
        }
        this.soundIntervals[interval] = true;
      }

      this.nth += 1;
      this.time = time;

      if (this.nth === this.updateTimeStateEvery) {
        this.updateState({ time });
        this.nth = 0;
      }
    }
  }

  start = (): void => {
    this.nth = 0;
    this.soundIntervals = { 10: false, 5: false, 4: false, 3: false, 2: false, 1: false, 0: false };
    if (this.props.settings.startCounterAt <= 5) {
      for (const property in this.soundIntervals) {
        if (parseInt(property) >= this.props.settings.startCounterAt) {
          this.soundIntervals[property] = true;
        }
      }
    }
    this.props.onEvent("start", this.props.settings.distance);
    this.startTimestamp = new Date().getTime() + (this.props.settings.startCounterAt * 1000);
    this.interval = setInterval(this.count, this.intervalTime);
  }

  calculateSplit = (totalLaps: number): number => {
    if (totalLaps > 0) {
      return this.time - this.state.lapList[0].timestamp;
    }
    return this.time;
  }

  calculateKmh = (time: number): number => {
    return (this.props.settings.distance / 1000) / ((time / 1000) / 3600);
  }

  formatKmH = (kmh: number): string => {
    return (kmh % 1 === 0) ? `${kmh}:00` : `${kmh}`;
  }

  // https://stackoverflow.com/questions/11832914/round-to-at-most-2-decimal-places-only-if-necessary
  round = (number: number): number => {
    return Math.round((number + Number.EPSILON) * 100) / 100
  }

  calculateRPM = (kmh: number): number => {
    const rpm = (kmh * 210) / this.props.settings.gear;
    return Math.round(rpm + Number.EPSILON);
  }

  lap = (): void => {
    this.beep1.play();
    let newMilestone = this.state.lapList;
    const number = this.state.lapList.length;
    const split = this.calculateSplit(number);
    const kmh = this.calculateKmh(split);
    let lapDifference = null;
    let totalDifference = null;

    if (this.props.settings.stopwatch === STOPWATCH.PURSUIT) {
      const _lapDifference = newMilestone.length > 0 ? this.props.settings.lapTime : this.props.settings.firstLapTime;
      lapDifference = ((_lapDifference * 1000) - split) / 1000;

      let _totalDifference = this.props.settings.firstLapTime;
      if (newMilestone.length > 0) {
        _totalDifference += (newMilestone.length * this.props.settings.lapTime)
      }
      totalDifference = ((_totalDifference * 1000) - this.time) / 1000;
    }

    newMilestone.unshift({
      number: number + 1,
      timestamp: this.time,
      split: formatTime(split),
      time: formatTime(this.time),
      speed: this.formatKmH(this.round(kmh)), // https://www.calculatorsoup.com/calculators/math/speed-distance-time-calculator.php
      rpm: this.calculateRPM(kmh),
      lapDifference,
      totalDifference,
    });

    this.updateState({
      lapList: newMilestone,
      lapDifference: lapDifference,
    });

    if (this.props.settings.stopwatch === STOPWATCH.PURSUIT) {
      setTimeout(() => {
        this.updateState({
          lapDifference: 0,
        });
      }, 5000)
    }
  }

  setAvgSpeed = (): void => {
    const speedArr = this.state.lapList
      .map(item => item.speed);

    this.avgSpeed = this.round(speedArr.reduce((a, b) => a + parseFloat(b), 0) / speedArr.length);
  }

  stop = (): void => {
    this.setAvgSpeed();
    if (this.time > 0) {
      this.lap();
    }
    this.stopTime = new Date().getTime();
    this.nth = 0;
    this.updateState({
      time: this.time,
      isStopped: true
    })
  }

  continue = (): void => {
    this.startTimestamp = this.startTimestamp + (new Date().getTime() - this.stopTime);
    this.updateState({
      isStopped: false
    });
  }

  reset = (): void => {
    this.props.onEvent("reset", undefined);
    clearInterval(this.interval);
    this.updateState({
      isStopped: false,
      time: 0,
      lapList: [],
      lapDifference: 0,
      save: false,
    })
  }

  save = (): void => {
    this.updateState({
      save: !this.state.save
    });
  }

  render = () => (
    <div className={`${css.container}`}>

      {this.state.save &&
        <div className={`${css.saveContainer}`}>
          <div className={`${css.saveContent}`}>
            <p>BLS has a lot of features planned for the Stopwatch app, including the <strong>SAVE</strong> feature where you'll be able to save your track records and have it displayed neatly in different formats.</p>
            <p>BLS will continue implementing these new features based on the interest and usage of the Stopwatch app.</p>
            <p>Help us out by sharing the app with your friends and social media.</p>
          </div>
        </div>
      }

      {this.props.settings.stopwatch === STOPWATCH.PURSUIT && /* Lap Difference */
        <div className={`${css.timeDifference} ${this.state.lapDifference >= 0 ? '' : css.negative} ${this.state.lapDifference === 0 ? css.fadeOut : ''}`}>
          <div>
            {this.state.lapDifference ? this.state.lapDifference.toFixed(1).replace('-', '') : ''}
          </div>
        </div>
      }

      {this.state.time <= 0 && // the count downer 
        <div className={`${css['item-counter']}`}>
          <CountDown number={this.state.time === 0 ? this.props.settings.startCounterAt : -Math.round(this.state.time / 1000)} />
        </div>
      }

      {this.state.time > 0 && // The lap counter
        <div className={`${css['item-count-down']}`}>
          <div className={`${css.box}`}>

            <span className={css.clock}>
              {this.state.isStopped &&
                <>
                  <span className={css.a}>Total Time:</span>
                  <div className={css.c}>
                    <span className={css.d}>{this.props.settings.stopwatch}</span>
                    <span>{this.props.settings.distance * this.state.lapList.length}m</span>
                    <span>{this.props.settings.gear}"</span>
                    <span>{this.avgSpeed}km/h</span>
                  </div>
                </>
              }
              <span className={css.b}>
                {formatTime(this.state.time)}
              </span>
            </span>
          </div>
        </div>
      }

      {this.state.lapList.length > 0 &&
        <div className={`${css['item-laps']}`}>
          <div className={`${css['lap-container']}`}>
            <Lap key={'abc'} isHeader={true} item={{
              number: '#',
              split: 'Split',
              time: 'Time',
              speed: 'Km/h',
              rpm: 'RPM',
              lapDifference: null,
            }} />
            {this.state.lapList.map((val, i) => <Lap key={i} item={val} />)}
          </div>
        </div>
      }

      <div className={`${css['item-btns']}`}>
        <div className={`${css['btn-container']}`}>
          {this.state.isStopped &&
            <button type="button" onClick={this.reset} className={`${css['btn']} ${css['btn-stop']}`} {...gtmAttr(CATEGORY.STOPWATCH, ACTION.STOPWATCH_RESET)}>Reset</button>
          }
          {this.state.time === 0 &&
            <button type="button" onClick={this.start} className={`${css['btn']} ${css['btn-start']}`} {...gtmAttr(CATEGORY.STOPWATCH, ACTION.STOPWATCH_START)}>Start</button>
          }
          {this.state.isStopped && this.state.time > 0 &&
            <button type="button" onClick={this.save} className={`${css['btn']} ${css['btn-save']}`} {...gtmAttr(CATEGORY.STOPWATCH, ACTION.STOPWATCH_SAVE)}>
              {this.state.save ? 'Close' : 'Save'}
            </button>
          }
          {!this.state.isStopped && this.state.time !== 0 &&
            <button type="button" onClick={this.stop} className={`${css['btn']} ${css['btn-stop']}`} {...gtmAttr(CATEGORY.STOPWATCH, ACTION.STOPWATCH_STOP)}>Stop</button>
          }
          {this.state.isStopped && this.state.time < 0 &&
            <button type="button" onClick={this.continue} className={`${css.btn}`} {...gtmAttr(CATEGORY.STOPWATCH, ACTION.STOPWATCH_CONTINUE)}>Continue</button>
          }
          {!this.state.isStopped && this.state.time > 0 &&
            <button type="button" onClick={this.lap} className={`${css.btn}`} {...gtmAttr(CATEGORY.STOPWATCH, ACTION.STOPWATCH_LAP)}>Lap</button>
          }
        </div>
      </div>
    </div>
  );
}

export default Stopwatch;
